<template>
  <div
    class="relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500"
  >
    <div class="w-40 mb-14 h-32">
      <img
        class="group-hover:animate-pulse w-full max-w-full"
        :src="imageUrl"
      />
    </div>
    <div>
      <h3 class="text-xl font-medium">
        <router-link :to="url" class="focus:outline-none">
          <span class="absolute inset-0" aria-hidden="true"></span>
          {{ title }}
        </router-link>
      </h3>
      <p class="mt-2 text-sm text-gray-500">
        {{ description }}
      </p>
    </div>
    <span
      class="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
      aria-hidden="true"
    >
      <svg
        class="h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z"
        ></path>
      </svg>
    </span>
  </div>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  props: {
    imageUrl: String,
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  name: "SingleQuickLink"
});
</script>

<style></style>
