<template>
  <PageWrapDefault>
    <div
      class="rounded-lg divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:divide-x sm:grid-cols-3"
    >
      <SingleQuickLink
        title="Emergency Maintenance"
        description="Restrict access to the platform during maintenance"
        url="/admin/set-emergency"
        :imageUrl="getEmergencyMaintenanceImageUrl"
      />
      <SingleQuickLink
        title="Quote Validation"
        description="Block quoting for counties, cities and zip"
        url="/admin/quoteCountyValidations"
        :imageUrl="getQuoteValidationImageUrl"
      />
      <SingleQuickLink
        title="Prohibit Binding"
        description="Prohibit binding for all counties in Texas to prevent quotes from being bound"
        url="/admin/counties"
        :imageUrl="getProhibitBindingImageUrl"
      />
    </div>
  </PageWrapDefault>
</template>

<script>
import SingleQuickLink from "@/components/QuickLinks/SingleQuickLink.vue";
import Vue from "vue";

import { getSystemFilesURL } from "@/helpers";

export default Vue.extend({
  components: { SingleQuickLink },
  name: "Blocking",
  computed: {
    getQuoteValidationImageUrl() {
      return getSystemFilesURL("quote-validation");
    },
    getProhibitBindingImageUrl() {
      return getSystemFilesURL("prohibit-binding");
    },
    getEmergencyMaintenanceImageUrl() {
      return getSystemFilesURL("emergency-maintenance");
    }
  }
});
</script>
